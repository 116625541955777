import React from 'react';
import logo from './logo.svg';
import './App.css';

class App extends React.Component {
  private ref?: HTMLDivElement;
  private ref1?: HTMLDivElement;

  public componentDidMount() {
    const x = window as any;
    console.log(x.React);
    console.log(x.ReactDOM);
    const req = new XMLHttpRequest();
    req.open("GET", "./main.plugin.js");
    req.onload = () => {
      console.log(req.status);
      if (req.status === 200) {
        console.log(req.responseText);
        // eslint-disable-next-line
        eval(req.responseText).default;
        setTimeout(() => {
          if (this.ref)
            (document as any).startPlugin(this.ref, "huhu1", (document as any).startPlugin);
          if (this.ref1)
            (document as any).startPlugin(this.ref1, "huhu2", (document as any).startPlugin);
        }, 2000);
      }
    };
    req.send();
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.tsx</code> and save to reload.
        </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
        </a>
          <div ref={this.setRef} style={{ margin: 10 }}>
            Hier kommt die Maus1
        </div>
          <div ref={this.setRef1} style={{ margin: 10 }}>
            Hier kommt die Maus1
        </div>
        </header>
      </div>
    );
  }

  private setRef = (ref: HTMLDivElement) => this.ref = ref;
  private setRef1 = (ref: HTMLDivElement) => this.ref1 = ref;
}
export default App;

